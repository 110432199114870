import React from 'react'
import { Link } from 'gatsby'

import './styles.scss'

const Breadcrumbs = props => {
	switch (props.template) {
		case 'product':
			return (
				<span className="breadcrumbs tablet-and-up">
					<Link className="gradient-text-rotated" to="/">
						Home
					</Link>
					<span className="gradient-text-rotated">{' / '}</span>
					<Link className="gradient-text-rotated" to="/shop">
						Products
					</Link>
					<span className="gradient-text-rotated">{' / '}</span>
					<span className="gradient-text-rotated">{props.productTitle}</span>
				</span>
			)
		default:
			return null
	}
}

export default Breadcrumbs
