import React, { useState, useEffect, Fragment } from 'react'
import './PDP2.scss'
import { getMetafield } from '../../helpers'
import pdp2 from '../../images/pdp2.png'
import arrow from '../../images/arrow.png'

const PDP2 = ({ metafields, product, title, subtitle, t2Ref }) => {
	const scrollTo = () => {
		window.scrollY = window.innerHeight * 2
	}
	console.log(metafields)
	console.log('pdp2')
	const [prodBenefits, setProdBenefits] = useState([])
	const [prod2image, setProd2Image] = useState([])
	useEffect(() => {
		console.log(metafields)
		if (metafields.length && getMetafield(metafields, 'product_benefits')) {
			console.log(getMetafield(metafields, 'product_benefits'))
			const productBenefitMetafield = JSON.parse(
				getMetafield(metafields, 'product_benefits')
			)
			const product2imageMetafield = JSON.parse(
				getMetafield(metafields, 'product_2_image')
			)
			
			if (productBenefitMetafield) {
				setProdBenefits(productBenefitMetafield)
			}
			if (product2imageMetafield) {
				setProd2Image(product2imageMetafield[0].src)
			}
			console.log('meta image')
			console.log(product2imageMetafield)
		}
	}, [metafields.length])

	return (
		<div className="pdp-2">
			<div className="pdp-details-container relative">
				<div ref={t2Ref} className="trigger" />
				<p className="subheading roboto-txt-sm left-bar">{title}</p>
				<h3 className="gradient-text index left-bar u-h3">03</h3>
				<div className="d-md-none moreInfo tr" onClick={scrollTo}>
					<p className="gradient-text">More info</p>
					<img className="downArrow" src={arrow} alt="Down Arrow" />
				</div>
				<div className="media d-flex">
					<img src={prod2image} alt="Product Benefits" />
				</div>
				<h3 className="tc gradient-text heading u-h1 tr">Good for</h3>
				<p className="subtitle roboto-txt-sm d-none d-md-block">{subtitle}</p>
				<div className="content">
					<ol>
						{prodBenefits.length ? (
							prodBenefits.map((mf, i) => <li key={i}>
								{i < 9 ? '0' : ''}{i+1}. {mf}
							</li>)
						) : (
							<Fragment>
								<li>01. Lorem Ipsum.</li>
								<li>02. Dolor Sit Amet.</li>
								<li>03. Lorem Dolor.</li>
								<li>04. Amor amare sit.</li>
							</Fragment>
						)}
					</ol>
				</div>
				<div className="rightBar gradient-text-rotated roboto-txt-sm tablet-and-up">
					staykept.com
				</div>
			</div>
		</div>
	)
}
export default PDP2
