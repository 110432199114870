import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import SEO from '~/components/seo'
import PDP1 from '~/components/PDP1/PDP1'
import PDP2 from '~/components/PDP2/PDP2'
import PDP3 from '~/components/PDP3/PDP3'
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs'

import ATCBarPDP from '~/components/ATCBar/ATCBarPDP'
import './styles.scss'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

const ProductPage = props => {
  const product = props.data.product
  const sub_product = props.data.sub_product
  const titleWords = product.title.split(' ')
  const title = titleWords[0]
  const subtitle = titleWords.slice(1, titleWords.length).join(' ')
  const metafields = props.data.storefront.productByHandle.metafields.edges
  console.log(metafields)
  console.log('pdp template')
  const [t1Ref, t1InView] = useInView()
  const [t2Ref, t2InView] = useInView()
  const [t3Ref, t3InView] = useInView()
  const [cartRef, cartInView] = useInView({
    threshold: 0.66,
  })
  console.log(sub_product)
  const [subscribing, setSubscribing] = useState(false)
  useEffect(() => {
    function animateCart() {
      const atc = document.querySelector('.cart-container')
      if (atc) {
        if (cartInView) {
          atc.classList.remove('with-thumb')
        } else {
          atc.classList.add('with-thumb')
        }
      }
    }
    animateCart()
  }, [cartInView])
  useEffect(() => {
    function changeCartQuantityColor() {
      const cartQuantity = document.querySelector('.cart-quantity')
      if (t1InView) {
        cartQuantity.classList.add('black')
      }
      if (t2InView) {
        cartQuantity.classList.remove('black')
      }
      if (t3InView) {
        cartQuantity.classList.add('black')
      }
    }
    changeCartQuantityColor()
  }, [t1InView, t2InView, t3InView])
  return (
    <>
      <SEO title={product.title} description={product.description} />
      <Breadcrumbs template="product" productTitle={product.title} />
      <PDP1
        product={product}
        title={title}
        subtitle={subtitle}
        metafields={metafields}
        cartRef={cartRef}
        t1Ref={t1Ref}
        subscribing={subscribing}
        setSubscribing={setSubscribing}
      />
      <PDP2
        product={product}
        title={title}
        subtitle={subtitle}
        metafields={metafields}
        t2Ref={t2Ref}
      />
      <PDP3
        title={title}
        product={product}
        subtitle={subtitle}
        metafields={metafields}
        t3Ref={t3Ref}
      />
      <ATCBarPDP
        subscriptionProduct={sub_product}
        product={product}
        metafields={metafields}
        subscribing={subscribing}
      />
    </>
  )
}

export const query = graphql`
  query($handle: String!, $sub_handle: String!) {
    storefront {
      productByHandle(handle: $handle) {
        title
        metafields(first: 100) {
          edges {
            node {
              key
              value
            }
          }
        }
      }
    }
    sub_product: shopifyProduct(handle: { eq: $sub_handle }) {
      variants {
        shopifyId
      }
    }
    product: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(width: 58, height: 58) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default ProductPage
