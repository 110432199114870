import React, { useState, useEffect } from 'react'
import Image from 'gatsby-image'
import {gsap} from 'gsap' 
import {CSSPlugin} from 'gsap/CSSPlugin'

import ProductForm from '~/components/ProductForm'
import arrow from '../../images/arrow.png'
import './PDP1.scss'

import { ProductDescription } from './styles'
import { set } from 'lodash'

const PDP1 = ({
	cartRef,
	t1Ref,
	product,
	metafields,
	title,
	subtitle,
	subscribing,
	setSubscribing,
}) => {
	const [selectedImg, setSelectedImg] = useState({})
	const [selectedImageIndex, setSelectedImageIndex] = useState(0)
	var sectionStyle = {
		backgroundSize: '60%',
		backgroundImage: `url(${selectedImg.originalSrc})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	}
	useEffect(() => {
		if (product.images.length) {
			handleSelectedImage(product.images[0], 0)
		}
	}, [product])
	
	const handleSelectedImage = (image, index) => {
		setSelectedImageIndex(index)
		setSelectedImg(image)
	}
	
	const scrollTo = () => {
		window.scrollY = window.innerHeight
	}
	gsap.registerPlugin(CSSPlugin)
	const h1Ref = React.useRef();
	const imageMain = React.useRef();
	const description = React.useRef();
	const form = React.useRef();
	useEffect(()=>{
		console.log('running')
		let tl = gsap.timeline();
		
		tl
		.from(imageMain.current, {autoAlpha:0, translateX: '80', duration: 1}, "intro")
		.from(h1Ref.current, {opacity:0, translateY: '-80px', duration: 1}, "intro")
		.from(description.current, {opacity:0, translateY: '80px', duration: 1}, "intro")
		.from(form.current, {opacity:0, translateY: '80px', duration: 1, delay: 0.2}, "intro")
	},[])

	return (
		<div className="pdp-1 d-flex flex-row relative">
			<div ref={t1Ref} className="trigger" />
			<div className="col-6 pl-80 d-flex pdp-half pdp-left-half flex-grow-1">
				<h1 className="u-h0" ref={h1Ref}>{title}</h1>
				<div className="mobile more-info-1" onClick={scrollTo}>
					<p className="gradient-text">More info</p>
					<img className="down-arrow" src={arrow} alt="Down arrow" />
				</div>
				<div className="d-flex product-info">
					<p className="subtitle roboto-txt-sm">{subtitle}</p>
					<div className="product-details">
						<ProductDescription
							ref={description}
							className="product-desc tablet-and-up"
							dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
						/>
						<div ref={form}>
							<ProductForm
								
								product={product}
								metafields={metafields}
								subscribing={subscribing}
								setSubscribing={setSubscribing}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="pdp-divider" />
			<div ref={cartRef} className="col-6 pl-0 pr-0 pdp-half pdp-right-half">
				<div className="image-thumbs">
					{product.images.map((image, index) => (
						<div onClick={() => handleSelectedImage(image, index)} className={selectedImageIndex === index ? 'image-thumb active' : 'image-thumb'} key={image.id}>
							<Image
								fixed={image.localFile.childImageSharp.fixed}
								alt={product.title}
							/>
						</div>
					))}
				</div>
				<div ref={imageMain} className="image-main">
					{selectedImg.localFile ?
						<Image
							fluid={selectedImg.localFile.childImageSharp.fluid}
							alt={product.title}
						/>
					: null }
				</div>
				<div className="border-gradient pdp-bottom-border"></div>
			</div>
		</div>
	)
}
export default PDP1
