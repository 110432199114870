import React, { useState, useEffect } from 'react'
import './PDP3.scss'
import { getMetafield } from '../../helpers'
import arrow from '../../images/arrow.png'
import pdp3 from '../../images/pdp3.png'

const PDP3 = ({ metafields, product, title, subtitle, t3Ref }) => {
	const scrollTo = () => {
		window.scrollY = window.innerHeight * 2
	}
	console.log(metafields)
	console.log('pdp3')
	const [pdpMain, setPDPMain] = useState('')
	const [pdpDesc, setPDPDesc] = useState('')
	const [prod3image, setProd3Image] = useState('')
	useEffect(() => {
		if (metafields.length) {
			const pdpMainMF = getMetafield(metafields, 'product_3_main')
			const pdpDescMF = getMetafield(metafields, 'product_3_desc')
			const product3imageMetafield = JSON.parse(getMetafield(metafields, 'product_3_image'))
			if (pdpMainMF) {
				setPDPMain(pdpMainMF)
			}
			if (pdpDescMF) {
				setPDPDesc(pdpDescMF)
			}
			if(product3imageMetafield){
				console.log(product3imageMetafield[0])
				console.log('3 image')
				setProd3Image(product3imageMetafield[0].src)
			}
			
		}
	}, [metafields.length])

	return (
		<div className="pdp-delivery-container relative">
			<div ref={t3Ref} className="trigger" />
			<h3 className="index gradient-text left-bar u-h3">03</h3>
			<h3 className="heading gradient-text-rotated u-h3">
				{pdpMain ? pdpMain : 'We deliver right to your doorstep.'}
			</h3>
			<p className="subheading roboto-txt-sm left-bar">{title}</p>
			<p className="subtitle tablet-and-up roboto-txt-sm">{subtitle}</p>
			<p className="content">
				{pdpDesc
					? pdpDesc
					: 'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut laoreet sodales nisi, quis iaculis nulla.'}
			</p>
			<div className="media">
				<img className="pdp-img-3" alt="Product Delivery" src={prod3image} />
			</div>
			<div className="rightBar gradient-text-rotated roboto-txt-sm tablet-and-up">
				staykept.com
			</div>
			<div className="d-md-none moreInfo tr" onClick={scrollTo}>
				<p className="gradient-text">More info</p>
				<img className="downArrow" src={arrow} alt="Down Arrow" />
			</div>
			<div id="trigger2" />
		</div>
	)
}
export default PDP3
