export const getMetafield = (metafields, key) => {
	const mfValue = metafields.filter(edge => edge.node.key === key)[0]
	return mfValue ? mfValue.node.value : ''
}

export const calculateShipping = (shipping, freeShipping) => {
	var trueShipping
	if (freeShipping) {
		trueShipping = 0
	} else {
		trueShipping = shipping
	}
	return trueShipping
}

export const formatDate = (date, step) => {
	var d = new Date(date)
	d.setDate(d.getDate() + step)
	return (
		(d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)) +
		'/' +
		(d.getDate() > 9 ? d.getDate() : '0' + d.getDate()) +
		'/' +
		d.getFullYear()
	)
}

export const formatMoney = number => {
	return '$' + (Number(number) / 100).toFixed(2)
}
