import React, { useState, useContext, useEffect, useCallback } from 'react'
import './ATCBar.scss'
import cart from './Cart.png'
import Image from 'gatsby-image'
import {gsap} from 'gsap'
import find from 'lodash/find'

import StoreContext from '~/context/StoreContext'
import { getMetafield } from '../../helpers'
const ATCBarPDP = ({
	product,
	subscriptionProduct,
	metafields,
	subscribing,
}) => {
	const {
		variants,
		variants: [initialVariant],
		priceRange: { minVariantPrice },
	} = product
	const [variant, setVariant] = useState({ ...initialVariant })
	const [quantity, setQuantity] = useState(1)
	const {
		addVariantToCart,
		store: { client },
		openCart,
	} = useContext(StoreContext)

	const [isSubscription, setIsSubscription] = useState(false)
	useEffect(() => {
		if (metafields.length) {
			const subscriptionMF = metafields.filter(
				mf => mf.node.key === 'subscription_product'
			)
			if (subscriptionMF) {
				setIsSubscription(JSON.parse(subscriptionMF[0].node.value))
			}
		}
	}, [metafields, metafields.length])

	const productVariant =
		client.product.helpers.variantForOptions(product, variant) || variant
	const [available, setAvailable] = useState(productVariant.availableForSale)

	const checkAvailability = useCallback(
		productId => {
			client.product.fetch(productId).then(() => {
				// this checks the currently selected variant for availability
				const result = variants.filter(
					variant => variant.shopifyId === productVariant.shopifyId
				)
				setAvailable(result[0].availableForSale)
			})
		},
		[client.product, productVariant.shopifyId, variants]
	)

	useEffect(() => {
		checkAvailability(product.shopifyId)
	}, [productVariant, checkAvailability, product.shopifyId])

	const handleAddToCart = () => {
		const properties = []
		console.log(productVariant)
		var variantId = productVariant.shopifyId
		if (isSubscription && subscribing && subscriptionProduct) {
			properties.push({
				key: 'subscription_product',
				value: getMetafield(metafields, 'subscription_product'),
			})
			properties.push({
				key: 'subscription_discount',
				value: getMetafield(metafields, 'subscription_discount'),
			})
			properties.push({
				key: 'subscription_only',
				value: getMetafield(metafields, 'subscription_only'),
			})
			properties.push({
				key: 'subscription_id',
				value: getMetafield(metafields, 'subscription_id'),
			})
			variantId = subscriptionProduct.variants[0].shopifyId
		}
		addVariantToCart(variantId, quantity, properties)
	}
	const atcBar = React.useRef();
	React.useEffect(()=>{
		let tl = gsap.timeline();
		tl
		.fromTo(atcBar.current, {bottom: '-80'}, {bottom: '0', duration: 1.4, ease: 'power2.out'})
	},[])
	return (
		<div
			ref={atcBar}
			className="atc-bar atc-bar-pdp d-flex"
			onClick={() => {
				if (available) {
					handleAddToCart()
					setTimeout(openCart, 500)
				}
			}}
		>
			<div className="pdp-container">
				{product.images.length ? (
					<div id="pdp-atc-img" style={{ width: '60px' }}>
						<Image
							fluid={product.images[0].localFile.childImageSharp.fluid}
							alt={product.images[0].title}
						/>
					</div>
				) : null}
				<div className="d-flex align-center cart-container">
					<div className="border-gradient"></div>
					<span className="roboto-txt-sm d-none d-lg-block">
						{available ? 'Add to Cart' : 'Out of Stock'}
					</span>
					<img src={cart} alt="cart" />
				</div>
			</div>
		</div>
	)
}

export default ATCBarPDP
